import React from "react";
import styles from "../sidePanel/ConstructionCalculationsPanel.module.scss";

import type ConstructionCalculationsBodyProps from "./ConstructionCalculationsBodyProps";
import type RenderableEditableProperty from "../../../../types/RenderableEditableProperty";
import CalculationMastTabs from "../tabs/CalculationMastTabs";
import CalculationMastResults from "../mastsResults/CalculationMastResults";
import IsaacEmpty from "../../../../components/isaacEmpty/IsaacEmpty";
import IsaacList from "../../../../components/isaacList/IsaacList";
import useEditablePropertyHook from "./useEditablePropertyHook";
import CalculationConstructionDetails from "../constructionDetails/CalculationConstructionDetails";

const ConstructionCalculationsBody: React.FunctionComponent<
  ConstructionCalculationsBodyProps
> = ({
  construction,
  constructionDetails,
  profileCalculationResults,
  selectedMastNo,
}) => {
  const {items} = useEditablePropertyHook(
    construction,
    profileCalculationResults?.editableConstructionProperties,
  );

  return (
    <>
      {profileCalculationResults !== undefined ? (
        <div
          className="ms-Grid"
          dir="ltr"
          style={{paddingLeft: 0, paddingRight: 0}}>
          <div className="ms-Grid-row">
            <div className={"ms-Grid-col ms-md10"}>
              <h3 style={{marginBottom: "5px"}}>Results</h3>
              <div className={styles.resultNavigation}>
                {constructionDetails !== undefined && (
                  // Note: constructionDetails should be part of profileCalculationResults. Also as a concept profile calculation should be removed.
                  <CalculationMastTabs
                    constructionDetails={constructionDetails}
                    profileCalculationResults={profileCalculationResults}
                    selectedMastNo={selectedMastNo}
                  />
                )}
              </div>
              <div className={styles.resultContainer}>
                <CalculationMastResults
                  mastResults={profileCalculationResults?.mastResults?.find(
                    (mr: any) => mr.mastNumber === selectedMastNo,
                  )}
                />
                <div
                  className={"ms-Grid-col ms-md2"}
                  style={{marginRight: "10px", marginLeft: "10px"}}>
                  <h3
                    style={{
                      borderBottom: "1px solid #e5e5e5",
                      paddingBottom: "15px",
                    }}>
                    Mast profile & foundation
                  </h3>
                  {selectedMastNo != null &&
                    constructionDetails !== undefined && (
                      <CalculationConstructionDetails
                        input={constructionDetails}
                        profileCalculationResults={profileCalculationResults}
                        selectedMastNumber={selectedMastNo}
                      />
                    )}

                  {items !== undefined && items.length > 0 && (
                    <>
                      <h3
                        style={{
                          borderBottom: "1px solid #e5e5e5",
                          paddingBottom: "15px",
                        }}>
                        Editable properties
                      </h3>
                      <IsaacList<RenderableEditableProperty>
                        items={items}
                        onRenderCell={item => (
                          <>
                            {`${item?.entity} - ${item?.property} :`}{" "}
                            <s>{item?.oldValue}</s> <b>{item?.currentValue}</b>
                          </>
                        )}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <IsaacEmpty message="No data to show for the current calculation." />
      )}
    </>
  );
};

export default ConstructionCalculationsBody;
