import type {ICommandBarItemProps} from "@fluentui/react";

export const getDefaultCommandBarItems = (
  showSaveModifiedBtn: boolean | undefined,
  handleOpenClosePanel: () => void,
  handleSaveModifiedConstruction: () => Promise<void>,
): any => {
  const commandBarItems: ICommandBarItemProps[] = [];

  commandBarItems.push({
    key: "calculations",
    name: "View all calculations",
    iconProps: {
      iconName: "SidePanel",
    },
    ariaLabel: "View calculation status",
    onClick: () => {
      handleOpenClosePanel();
    },
    type: "button",
  });

  if (showSaveModifiedBtn !== undefined && showSaveModifiedBtn) {
    commandBarItems.push({
      key: "saveModified",
      name: "Save modified construction",
      iconProps: {
        iconName: "SaveAs",
      },
      onClick: () => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        (async () => {
          await handleSaveModifiedConstruction();
        })();
      },
      ariaLabel: "Save modified construction",
      type: "button",
    });
  }

  return commandBarItems;
};
