import {useState} from "react";
import {
  FontWeights,
  type IButtonStyles,
  type ICommandBarItemProps,
} from "@fluentui/react";
import ConstructionStatus from "../../../../types/ConstructionStatus";

const buttonStyles: IButtonStyles = {
  root: {
    selectors: {
      ".ms-Button-label": {fontWeight: FontWeights.bold, color: "#1e53a3"},
    },
  },
};

const useConstructionCommandBarItems = (
  constructionStatus: ConstructionStatus | undefined,
  isAllowToTakeAction: boolean,
  goToCalculations: () => void,
  handleShowDeleteDialogOpen: () => void,
  handleNewCalculationTrigger: () => Promise<void>,
  handleFreezeUnfreezeConstructionTrigger: () => Promise<void>,
): ICommandBarItemProps[] => {
  const items: ICommandBarItemProps[] = [];
  const [disabled, setDisabled] = useState<boolean>(false);

  if (isAllowToTakeAction) {
    const isAllowedToFreezeUnfreeze =
      constructionStatus === ConstructionStatus.Registered ||
      constructionStatus === ConstructionStatus.Frozen;

    if (constructionStatus !== ConstructionStatus.Frozen) {
      items.push({
        key: "NewCalculation",
        name: "New calculation",
        iconProps: {
          iconName: "AddNotes",
        },
        buttonStyles,
        ariaLabel: "New calculation",
        onClick: () => {
          setDisabled(prevState => !prevState);
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          handleNewCalculationTrigger();
          setTimeout((): void => {
            setDisabled(prevState => !prevState);
          }, 2000);
        },
        type: "button",
        disabled,
      });

      items.splice(2, 0, {
        key: "Delete",
        name: "Delete",
        iconProps: {
          iconName: "Delete",
        },
        buttonStyles,
        ariaLabel: "Delete construction",
        onClick: () => {
          handleShowDeleteDialogOpen();
        },
        type: "button",
      });
    }

    if (isAllowedToFreezeUnfreeze) {
      items.push({
        key: "FreezeUnfreeze",
        name:
          constructionStatus === ConstructionStatus.Frozen
            ? "Unfreeze"
            : "Freeze",
        iconProps: {
          iconName:
            constructionStatus === ConstructionStatus.Frozen
              ? "Sunny"
              : "Freezing",
        },
        buttonStyles,
        ariaLabel: "Freeze construction",
        type: "button",
        onClick: () => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          handleFreezeUnfreezeConstructionTrigger();
        },
      });
    }
  }

  items.splice(1, 0, {
    key: "calculateResults",
    name: "View calculations",
    iconProps: {
      iconName: "Calculator",
    },
    buttonStyles,
    ariaLabel: "View calculations",
    onClick: () => {
      goToCalculations();
    },
    type: "button",
  });

  return items;
};

export default useConstructionCommandBarItems;
