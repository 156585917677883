import React from "react";
import styles from "./Table.module.scss";

import type ITableProps from "./ITableProps";
import TranslatedText from "../translatedText/TranslatedText";
import TableRow from "./row/TableRow";
import type {RootState} from "../../../store";
import {useSelector} from "react-redux";
import type {SelectedEntity} from "../../../types/SelectedEntity";

const Table: React.FunctionComponent<ITableProps> = ({
  columns,
  values,
  name,
  parent,
  count,
  tableId,
  constructionId,
  isAllowToTakeAction,
}: ITableProps) => {
  const columnsWithoutId = columns.filter(
    c => c !== "id" && c !== "selectionId",
  );

  const {deselectedEntities} = useSelector(
    (state: RootState) => state.construction,
  );
  const checkDisabled = (
    tableName?: string,
    parent?: string,
    value?: any,
    deselectedEntities?: SelectedEntity[],
  ): boolean => {
    const id = value?.selectionId ?? 0;

    const entityType = parent ?? tableName;
    if (deselectedEntities !== null && parent !== null) {
      const data = deselectedEntities?.find(
        c => c.entityType === entityType && c.entityId === id,
      );
      if (data !== undefined) {
        return true;
      }
    }

    return false;
  };

  return (
    <div id={tableId} className={styles.constructionDataItem}>
      {(values.length > 0 || count === null) && (
        <div>
          <div className={styles.resultTableContainer}>
            <table className={styles.resultTable}>
              <thead>
                <tr className={styles.headers}>
                  {columnsWithoutId.map((column, i) => (
                    <th key={i}>
                      <TranslatedText value={column} parent={name ?? parent} />
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {values.length > 0 &&
                  values.map((value, i) => (
                    <TableRow
                      key={i}
                      id={i}
                      data={columnsWithoutId}
                      className={styles.result}
                      value={value}
                      name={name}
                      constructionId={constructionId}
                      isAllowToTakeAction={isAllowToTakeAction}
                      isDisabled={checkDisabled(
                        name,
                        parent,
                        value,
                        deselectedEntities,
                      )}
                    />
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

Table.displayName = "Table";

export default Table;
