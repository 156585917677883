import React, {useState} from "react";
import {Toggle} from "@fluentui/react";
import type IsaacEnabledButtonProps from "./IsaacEnableButtonProps";
import {useDispatch} from "react-redux";
import {updateSelectedEntities} from "../../pages/construction/constructionSlice";

const IsaacEnabledButton: React.FunctionComponent<IsaacEnabledButtonProps> = ({
  isEnabled,
  entityType,
  entityId,
}) => {
  const [check, setCheck] = useState<boolean>(isEnabled);
  const dispatch = useDispatch();

  const onChange = (
    ev: React.MouseEvent<HTMLElement>,
    checked?: boolean,
  ): void => {
    setCheck(!check);
    dispatch(
      updateSelectedEntities({
        entityType,
        entityId,
        checked: check,
      }),
    );
  };
  return (
    <div style={{width: 80}}>
      <Toggle checked={check} onText="On" offText="Off" onChange={onChange} />
    </div>
  );
};

export default IsaacEnabledButton;
