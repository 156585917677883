import React from "react";
import {Provider} from "react-redux";
import {RouterProvider} from "react-router-dom";
import {AuthProvider} from "./services/auth/AuthContext";
import IsaacErrorBoundary from "./components/isaacErrorBoundary/IsaacErrorBoundary";
import {store} from "./store";
import router from "./routes/router";
import {ApiProvider} from "./services/api/ApiContext";

const App: React.FunctionComponent = () => {
  return (
    <IsaacErrorBoundary>
      <Provider store={store}>
        <AuthProvider>
          <ApiProvider>
            <RouterProvider router={router} />
          </ApiProvider>
        </AuthProvider>
      </Provider>
    </IsaacErrorBoundary>
  );
};

export default App;
