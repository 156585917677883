import type {ICommandBarItemProps} from "@fluentui/react";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../../services/auth/hooks/useAuth";
import PathConstants from "../../../routes/pathConstants";
import ConstructionStatus from "../../../types/ConstructionStatus";
import IsaacTagDialogType from "../components/tags/types/IsaacTagDialogType";

const useConstructionCommandBarItems = (
  handleTagDialogDismiss: (type: string) => void,
  handleDownloadExcelInputMatrixTrigger: (items: any[]) => Promise<void>,
  handleDeleteConstructionTrigger: (items: any[]) => Promise<void>,
  handleFreezeUnfreezeConstructionTrigger: (items: any[]) => Promise<void>,
  handleDownloadJsonInputMatrixTrigger: (items: any[]) => Promise<void>,
): ((selectedConstructions: any[]) => ICommandBarItemProps[]) => {
  const navigate = useNavigate();
  const {user} = useAuth();
  const isAllowToTakeAction = user?.isAllowedToTakeAction();

  const AddFreezeUnfreezeButton = (
    selectedConstructions: any[],
    commandBarItems: ICommandBarItemProps[],
  ): void => {
    if (selectedConstructions.length > 0) {
      const isAllowedToFreezeUnfreeze =
        selectedConstructions?.every(
          c => c.status === ConstructionStatus.Registered,
        ) ||
        selectedConstructions?.every(
          c => c.status === ConstructionStatus.Frozen,
        );

      if (isAllowToTakeAction && isAllowedToFreezeUnfreeze) {
        commandBarItems.push({
          key: "FreezeUnfreeze",
          name:
            selectedConstructions[0]?.status === ConstructionStatus.Frozen
              ? "Unfreeze"
              : "Freeze",
          iconProps: {
            iconName:
              selectedConstructions[0]?.status === ConstructionStatus.Frozen
                ? "Sunny"
                : "Freezing",
          },
          ariaLabel: "Freeze construction",
          type: "button",
          onClick: () => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            handleFreezeUnfreezeConstructionTrigger(selectedConstructions);
          },
        });
      }
    }
  };

  const AddDeleteButton = (
    selectedConstructions: any[],
    commandBarItems: ICommandBarItemProps[],
  ): void => {
    if (selectedConstructions.length > 0) {
      const isAllowedToDelete =
        isAllowToTakeAction &&
        selectedConstructions?.every(
          c => c.status !== ConstructionStatus.Frozen,
        );
      if (isAllowedToDelete) {
        commandBarItems.push({
          key: "Delete",
          name: "Delete",
          iconProps: {
            iconName: "Delete",
          },
          split: true,
          ariaLabel: "Delete construction",
          onClick: () => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            handleDeleteConstructionTrigger(selectedConstructions);
          },
          type: "button",
          disabled: false,
        });
      }
    }
  };

  const AddViewCalculationResultsButton = (
    selectedConstructions: any[],
    commandBarItems: ICommandBarItemProps[],
  ): void => {
    if (selectedConstructions.length === 1) {
      commandBarItems.push({
        key: "calculateResults",
        name: "View Calculation Result",
        iconProps: {
          iconName: "Calculator",
        },
        ariaLabel: "View Calculation Result",
        onClick: () => {
          navigate(
            `${PathConstants.Constructions}/${selectedConstructions[0].id}/${PathConstants.Calculations}`,
          );
        },
        type: "button",
      });
    }
  };

  const AddSelectConstructionButton = (
    selectedConstructions: any[],
    commandBarItems: ICommandBarItemProps[],
  ): void => {
    if (selectedConstructions.length <= 0) {
      commandBarItems.push({
        key: "Actions",
        name: "Select one or more constructions for action bar to show",
        iconProps: {
          iconName: "SetAction",
        },
        ariaLabel: "Constructions actions",
        type: "text",
        disabled: true,
      });
    }
  };

  const AddManageTagsButton = (
    selectedConstructions: any[],
    commandBarItems: ICommandBarItemProps[],
  ): void => {
    if (isAllowToTakeAction) {
      if (selectedConstructions.length === 1) {
        commandBarItems.push({
          key: "ManageTags",
          name: "Manage Tags",
          iconOnly: false,
          iconProps: {
            iconName: "Tag",
          },
          subMenuProps: {
            items: [
              {
                key: "emailMessage",
                text: "Add tags",
                iconProps: {iconName: "Add"},
                onClick: () => {
                  handleTagDialogDismiss(IsaacTagDialogType.ADD);
                },
              },
              {
                key: "calendarEvent",
                text: "Remove tags",
                iconProps: {iconName: "Remove"},
                onClick: () => {
                  handleTagDialogDismiss(IsaacTagDialogType.DELETE);
                },
              },
            ],
            directionalHintFixed: true,
          },
          ariaLabel: "Manage Tags",
          disabled: false,
        });
      }
    }
  };

  const AddDownloadInputMatrixButton = (
    selectedConstructions: any[],
    commandBarItems: ICommandBarItemProps[],
  ): void => {
    if (isAllowToTakeAction && selectedConstructions.length > 0) {
      commandBarItems.push({
        key: "InputMatrix",
        name: "Input Matrix",
        iconOnly: false,
        iconProps: {
          iconName: "Download",
        },
        subMenuProps: {
          items: [
            {
              key: "downloadExcelMatrix",
              text: ".xlsx",
              iconProps: {iconName: "ExcelDocument"},
              onClick: () => {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                handleDownloadExcelInputMatrixTrigger(selectedConstructions);
              },
            },
            {
              key: "downloadJsonMatrix",
              text: ".json",
              iconProps: {iconName: "Embed"},
              onClick: () => {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                handleDownloadJsonInputMatrixTrigger(selectedConstructions);
              },
            },
          ],
          directionalHintFixed: true,
        },
        ariaLabel: "Input Matrix",
        disabled: false,
      });
    }
  };

  const AddViewDetailsButton = (
    selectedConstructions: any[],
    commandBarItems: ICommandBarItemProps[],
  ): void => {
    if (selectedConstructions.length === 1) {
      commandBarItems.push({
        key: "Details",
        name: "Details",
        iconProps: {
          iconName: "View",
        },
        ariaLabel: "view details of construction",
        onClick: () => {
          navigate(
            `${PathConstants.Constructions}/${selectedConstructions[0].id}`,
          );
        },
        type: "button",
      });
    }
  };

  const getItems = (selectedConstructions: any[]): ICommandBarItemProps[] => {
    const commandBarItems: ICommandBarItemProps[] = [];

    AddSelectConstructionButton(selectedConstructions, commandBarItems);
    AddViewDetailsButton(selectedConstructions, commandBarItems);
    AddFreezeUnfreezeButton(selectedConstructions, commandBarItems);
    AddDeleteButton(selectedConstructions, commandBarItems);
    AddViewCalculationResultsButton(selectedConstructions, commandBarItems);
    AddManageTagsButton(selectedConstructions, commandBarItems);
    AddDownloadInputMatrixButton(selectedConstructions, commandBarItems);

    return commandBarItems;
  };

  return getItems;
};

export default useConstructionCommandBarItems;
