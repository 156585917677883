// Note: Please remove the following comments and fix linting issues
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable */

import React from "react";
import styles from "../sidePanel/ConstructionCalculationsPanel.module.scss";
import {
  TooltipHost,
  DirectionalHint,
  Icon,
  type ITooltipHostStyles,
} from "@fluentui/react";
import {HeadersTooltips} from "./HeaderToolTips";

// Note: Most of this file is logic ported from old FE. Should be refactored
const CalculationMastResults: React.FunctionComponent<{
  mastResults: any;
}> = ({mastResults}) => {
  const mastResultsAreAvailable =
    mastResults != null && mastResults?.loadCaseResults?.length > 0;
  return (
    <>
      {mastResultsAreAvailable && (
        <div className={styles.mastBtn} style={{width: "85%"}}>
          <table className={styles.resultTable}>
            <thead>
              <tr className={styles.headerCategories}>
                <th key="empty-0" style={{minWidth: "60px"}} />
                <th key="[kN]" colSpan={3}>
                  [kN]
                </th>
                <th key="[kNm]" colSpan={3}>
                  [kNm]
                </th>
                <th key="[MPa]" colSpan={2}>
                  [MPa]
                </th>
                <th key="[mm]" colSpan={2}>
                  [mm]
                </th>
                <th key="[rad]" colSpan={1}>
                  [rad]
                </th>
                <th key="empty-1" colSpan={5} />
              </tr>

              <tr className={styles.headers}>
                {renderHeaders(mastResults.loadCaseResults)}
              </tr>

              <tr className={styles.emptyRow}>
                <td />
              </tr>
              {renderResults(mastResults.loadCaseResults)}
            </thead>
          </table>
        </div>
      )}
    </>
  );
};

const renderHeaders = (mast: any): any => {
  const headers = [];

  for (let i = 0; i < mast[0].loadCaseResultValues.length; i++) {
    headers.push(mast[0].loadCaseResultValues[i].abbreviation);
  }

  for (let i = 0; i < mast[0].validationControls.length; i++) {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (mast[0].validationControls[i].isVisible) {
      headers.push(mast[0].validationControls[i].abbreviation);
    }
  }

  // eslint-disable-next-line react/jsx-key
  const results = [<th key="def" />];

  const hostStyles: Partial<ITooltipHostStyles> = {root: {display: "grid"}};
  const calloutProps = {gapSpace: 0};

  for (let i = 0; i < headers.length; i++) {
    if (
      (headers[i] === "DBL" ||
        headers[i] === "DML" ||
        headers[i] === "BT" ||
        headers[i] === "zt" ||
        headers[i] === "ft") &&
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      HeadersTooltips[headers[i]]
    ) {
      results.push(
        <th key={i} style={{cursor: "pointer"}}>
          <TooltipHost
            content={
              <div>
                <div style={{fontSize: "16px", marginBottom: "10px"}}>
                  {HeadersTooltips[headers[i]].title}
                </div>
                <img src={HeadersTooltips[headers[i]].image} />
                <div style={{fontStyle: "italic", marginTop: "10px"}}>
                  {HeadersTooltips[headers[i]].des}
                </div>
              </div>
            }
            id={styles.tooltipRes}
            calloutProps={calloutProps}
            styles={hostStyles}
            directionalHint={DirectionalHint.bottomCenter}>
            <div>{headers[i]}</div>
          </TooltipHost>
        </th>,
      );
    } else {
      results.push(<th key={i}>{headers[i]}</th>);
    }
  }

  return results;
};

const renderResults = (mast: any): any => {
  const result = [];

  const hostStyles: Partial<ITooltipHostStyles> = {root: {display: "grid"}};
  const calloutProps = {gapSpace: 0};

  for (let i = 0; i < mast.length; i++) {
    result.push(
      <tr key={i} className={styles.result}>
        <td>
          <TooltipHost
            content={
              "Additional information for " +
              mast[i].loadCase.slsUls +
              mast[i].loadCase.wind.type +
              " " +
              mast[i].loadCase.wind.direction +
              " to show here"
            }
            id={styles.tooltip}
            calloutProps={calloutProps}
            styles={hostStyles}
            directionalHint={DirectionalHint.rightCenter}
            tooltipProps={{
              calloutProps: {
                styles: {
                  beak: {background: "#3d4046"},
                  beakCurtain: {background: "#3d4046"},
                  calloutMain: {background: "#3d4046"},
                },
              },
            }}>
            {mast[i].loadCase.slsUls +
              mast[i].loadCase.wind.type +
              " " +
              mast[i].loadCase.wind.direction}
          </TooltipHost>
        </td>

        {renderLoadcase(
          mast[i].loadCaseResultValues,
          mast[i].validationControls,
        )}
      </tr>,
    );
  }

  return result;
};

const renderLoadcase = (
  loadCaseResultValues: any,
  validationControls: any,
): any => {
  const results = [];

  for (let i = 0; i < loadCaseResultValues.length; i++) {
    if (loadCaseResultValues[i].hasValidationControl) {
      const className = loadCaseResultValues[i].isValid
        ? styles.greenBox
        : styles.redBox;

      results.push(
        <td
          key={`${i}-${loadCaseResultValues[i].value}`}
          className={className}
          title={loadCaseResultValues[i].value}>
          {Math.round((loadCaseResultValues[i].value + Number.EPSILON) * 100) /
            100}
        </td>,
      );
    } else {
      results.push(
        <td
          key={`${i}-${loadCaseResultValues[i].value}`}
          title={loadCaseResultValues[i].value}>
          {Math.round((loadCaseResultValues[i].value + Number.EPSILON) * 100) /
            100}
        </td>,
      );
    }
  }

  for (let i = 0; i < validationControls.length; i++) {
    if (validationControls[i].isVisible) {
      const className = validationControls[i].value
        ? styles.greenBox
        : styles.redBox;
      const value = validationControls[i].value ? (
        <Icon iconName="CheckMark" />
      ) : (
        <Icon iconName="Error" />
      );

      results.push(
        <td key={`${i}-${validationControls[i].value}`} className={className}>
          {value}
        </td>,
      );
    }
  }

  return results;
};

export default CalculationMastResults;
